import React, { useEffect, useState, createRef, useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import MediaQuery from "react-responsive"
import Questions from "../../../shared/questions/Questions"
import { CircularProgress } from "@mui/material"

import nextArrow from "../../../../assets/img/arrow-right-form.svg"
import nextArrowGrey from "../../../../assets/img/arrow-right-form-grey.svg"
import IconChevron from "../../../../assets/img/chevron-down.png"

import "../buySubscriptions/BuySubscriptions.css"
import "./Questionary.css"

import {
  PlanNewSubscriptionService,
  checkOldClientService,
  accessControlService
} from "../../../../services/planNewSubscriptionService"
import { checkTypePlanService } from "../../../../services/planRenewSubscriptionService"

import { PersonalData } from "../../../../models/formsModels/PersonalData"
import { SportActivityData } from "../../../../models/formsModels/SportActivityData"
import { FeedingData } from "../../../../models/formsModels/FeedingData"

import previousArrow from "../../../../assets/img/arrow-left-form.svg"

import partyIcon from "../../../../assets/img/fin-suscripcion-icon.png"
import infoIcon from "../../../../assets/img/info-icon-form.svg"
import screenShotExample from "../../../../assets/img/Screenshot-example-form.svg"
import def_img from "../../../../assets/img/default_form_image.png"

import PrivateLoading from "../../../shared/loading/PrivateLoading"
import SportActivityBuySubscriptions from "../buySubscriptions/SportActivityBuySubscriptions"
import FeedingDataBuySubscriptions from "../buySubscriptions/FeedingDataBuySubscriptions"
import Input from "./components/Input"
import RadioGroup from "./components/RadioGroup"
import { SubmitHandler, useForm } from "react-hook-form"
import WeeklyInput from "./components/WeeklyInput"
import FoodFrequency from "./components/FoodFrequency"
import StepController from "./components/StepController"
import MealDescription from "./components/MealDescription"
import sendGeopolQuestionary from "../../../../services/sendGeopolQuestionary"
import { getLastDietPlan, UserDiet } from "../../../../utils/dietPlanStorage"

const foodCategories = [
  { key: "whiteMeat", label: "Carne blanca (aves, cerdo o ternera blanca)" },
  { key: "redMeat", label: "Carne roja (ternera, caza)" },
  { key: "processedMeat", label: "Carnes procesadas (fiambres, embutidos)" },
  { key: "fishSeafood", label: "Pescados / mariscos (tanto blancos como azules)" },
  { key: "bread", label: "Pan (blanco, integral, molde, etc.)" },
  { key: "cereals", label: "Cereales (arroz, trigo, avena, quínoa, etc.)" },
  { key: "tubers", label: "Tubérculos (patata, boniato, yuca, etc.)" },
  { key: "fruits", label: "Frutas" },
  { key: "vegetables", label: "Vegetales y hortalizas" },
  { key: "legumes", label: "Legumbres (guisantes, lentejas, soja, etc.)" },
  { key: "eggs", label: "Huevos" },
  { key: "dairy", label: "Lácteos y derivados (leche, yogur, kéfir, quesos, etc.)" },
  {
    key: "fats",
    label:
      "Grasas (aceite, aguacate, aceitunas, mantequilla, frutos secos, semillas, chocolate, etc.)"
  },
  {
    key: "ultraProcessed",
    label: "Ultraprocesados (bollería, galletas, snacks tipo patatas chips o dulces, etc.)"
  },
  { key: "softDrinks", label: "Bebidas (refrescos con o sin gas)" },
  { key: "alcoholicDrinks", label: "Bebidas alcohólicas (vino, cerveza, licores, etc.)" },
  { key: "waterInfusions", label: "Agua / infusiones" }
]

type FoodFrequency = Record<string, "nunca" | "diario" | "semanal" | "ocasional" | "mensual">

const days = [
  { key: "monday", label: "Lunes" },
  { key: "tuesday", label: "Martes" },
  { key: "wednesday", label: "Miércoles" },
  { key: "thursday", label: "Jueves" },
  { key: "friday", label: "Viernes" },
  { key: "saturday", label: "Sábado" },
  { key: "sunday", label: "Domingo" }
]

type WeeklyActivity = Record<string, { activity: string; hour: string }>

const meals = [
  { key: "breakfast", label: "Desayuno" },
  { key: "snack", label: "Tentempié" },
  { key: "lunch", label: "Comida" },
  { key: "afternoonSnack", label: "Merienda" },
  { key: "dinner", label: "Cena" },
  { key: "other", label: "Otra" }
] as const

type MealTime = "breakfast" | "snack" | "lunch" | "afternoonSnack" | "dinner" | "other"

type GeopolQuestionary = {
  personalDataToSend: {
    dpemail: string | null | undefined
    dptelefono: string
    dpnacimiento: string
    dpaltura: string
    dppeso: string
    dpsexo: "h" | "m"
    dpcintura: string
    dpabdomen: string
    dpcadera: string
    dpoposicion: "guardia" | "policia"
    dpfechaoposicion: string
  }
  sportActivityDataToSend: {
    weekly: WeeklyActivity
    general: string
  }
  feedingDataToSend: {
    frequency: FoodFrequency
    meals: Record<MealTime, string>
  }
  photos: {
    front: File | null
    side: File | null
    back: File | null
  }
  cnewsletter: boolean
  cprivacidad: boolean
  planId: number | null | undefined
}

const GeoPOLQuestionary = () => {
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { errors }
  } = useForm<GeopolQuestionary>({})

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [clientData, setClientData] = useState(null)
  const [typePlan, setTypePlan] = useState("")
  const [access, setAccess] = useState(true)
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [hasBeenSent, setHasBeenSent] = useState(false)

  const [next, setNext] = useState(1)
  const [feeding, setFeeding] = useState(1)
  const [validationPassOne, setValidationPassOne] = useState(false)

  const [error, setError] = useState([])
  const [plan, setPlan] = useState<UserDiet | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const lastDietPlan = getLastDietPlan()
    if (!lastDietPlan || lastDietPlan.typePlan !== "geopol") {
      navigate("/private")
    }
    setPlan(lastDietPlan)
  }, [])

  const handleFinishFirstStep = useCallback(async () => {
    const result = await trigger("personalDataToSend")
    if (result) setNext(2)
  }, [])

  const handleFinishSecondStep = useCallback(async () => {
    const result = await trigger("sportActivityDataToSend")
    if (result) setNext(3)
  }, [])

  const handleFinishThirdStep = useCallback(async () => {
    const result = await trigger("feedingDataToSend")
    if (result) setNext(4)
  }, [])

  const handleGoBack = useCallback(() => {
    setNext(next - 1)
  }, [next])

  const onSubmit: SubmitHandler<GeopolQuestionary> = async data => {
    try {
      setSending(true)
      data.personalDataToSend["dpemail"] = localStorage.getItem("email")
      data.planId = Number(plan?.id)
      await sendGeopolQuestionary(data)
      setHasBeenSent(true)
    } catch (e) {
    } finally {
      setSending(false)
    }
  }

  return (
    <div className="vikika-suscription-forms questionary">
      {!hasBeenSent && (
        <div className="begin-form">
          <h2>CUESTIONARIO INICIAL GEOPOL</h2>
          <div className="data-form-titles">
            <div className="data-form-titles-checks">
              <span className="success-step sucess-step-icon">{next > 1 ? "✔" : 1}</span>
              <p className="success-step-p1">Datos personales</p>
            </div>
            <span
              className={
                next > 1
                  ? "line-progress line-progress-active-full"
                  : "line-progress line-progress-active"
              }
            ></span>

            <div className="data-form-titles-checks">
              <span className={next > 1 ? "success-step sucess-step-icon" : "sucess-step-icon"}>
                {next > 2 ? "✔" : 2}
              </span>
              <p className={next > 1 ? "success-step-p2" : ""}>Actividad diaria y deportiva</p>
            </div>
            <span
              className={
                next > 2
                  ? "line-progress line-progress-active-full"
                  : "line-progress line-progress-active"
              }
            ></span>

            <div className="data-form-titles-checks">
              <span className={next > 2 ? "success-step sucess-step-icon" : "sucess-step-icon"}>
                {next > 3 ? "✔" : 3}
              </span>
              <p className={next > 2 ? "success-step-p3" : ""}>Alimentación</p>
            </div>
            <span
              className={
                next > 3
                  ? "line-progress line-progress-active-full"
                  : "line-progress line-progress-active"
              }
            ></span>

            <div className="data-form-titles-checks">
              <span className={next > 3 ? "success-step sucess-step-icon" : "sucess-step-icon"}>
                {next > 3 ? "✔" : 4}
              </span>
              <p className={next > 3 ? "success-step-p4" : ""}>Confirmación</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {next === 1 && (
              <>
                <div className="input-container">
                  <Input
                    name={"personalDataToSend.dptelefono"}
                    type={"text"}
                    label={"*Teléfono"}
                    placeholder={"+34"}
                    register={register("personalDataToSend.dptelefono", {
                      required: "Este campo es obligatorio",
                      pattern: {
                        value: /^\+34\d{9}$/,
                        message: "El formato debe ser +34XXXXXXXXX (9 dígitos después del +34)"
                      }
                    })}
                    error={errors.personalDataToSend?.dptelefono?.message}
                  ></Input>
                  <Input
                    name={"personalDataToSend.dpnacimiento"}
                    type={"text"}
                    label={"*Año de nacimiento"}
                    placeholder={"YYYY"}
                    register={register("personalDataToSend.dpnacimiento", {
                      required: "Este campo es obligatorio",
                      pattern: {
                        value: /^(19|20)\d{2}$/,
                        message:
                          "El formato debe ser un año válido en formato YYYY (por ejemplo, 1990)"
                      }
                    })}
                    error={errors.personalDataToSend?.dpnacimiento?.message}
                  ></Input>
                </div>
                <div className="input-container">
                  <RadioGroup
                    name={"personalDataToSend.dpoposicion"}
                    label={"*Oposición"}
                    options={[
                      { value: "guardia", label: "Guardia Civil" },
                      { value: "policia", label: "Policia Nacional" }
                    ]}
                    register={register("personalDataToSend.dpoposicion", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpoposicion?.message}
                  />
                  <Input
                    name="personalDataToSend.dpfechaoposicion"
                    type="text"
                    label="*Fecha aproximada de pruebas físicas"
                    placeholder="YYYY-MM-DD"
                    register={register("personalDataToSend.dpfechaoposicion", {
                      required: "Este campo es obligatorio",
                      pattern: {
                        value: /^\d{4}-\d{2}-\d{2}$/, // Asegura el formato YYYY-MM-DD
                        message: "El formato debe ser YYYY-MM-DD"
                      }
                    })}
                    error={errors.personalDataToSend?.dpfechaoposicion?.message}
                  />
                </div>
                <div className="input-container">
                  <Input
                    name={"personalDataToSend.dpaltura"}
                    type={"text"}
                    label={"*Altura"}
                    placeholder={"En CM"}
                    register={register("personalDataToSend.dpaltura", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpaltura?.message}
                  ></Input>
                  <Input
                    name={"personalDataToSend.dppeso"}
                    type={"text"}
                    label={"*Peso"}
                    placeholder={"En KG"}
                    register={register("personalDataToSend.dppeso", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dppeso?.message}
                  ></Input>
                  <RadioGroup
                    name={"personalDataToSend.dpsexo"}
                    label={"*Sexo"}
                    options={[
                      { value: "h", label: "Hombre" },
                      { value: "m", label: "Mujer" }
                    ]}
                    register={register("personalDataToSend.dpsexo", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpsexo?.message}
                  />
                </div>
                <div className="input-container">
                  <Input
                    name={"personalDataToSend.dpcintura"}
                    type={"text"}
                    label={"*Medida cintura"}
                    placeholder={"En CM"}
                    register={register("personalDataToSend.dpcintura", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpcintura?.message}
                  ></Input>
                  <Input
                    name={"personalDataToSend.dpabdomen"}
                    type={"text"}
                    label={"*Medida abdomen"}
                    placeholder={"En CM"}
                    register={register("personalDataToSend.dpabdomen", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpabdomen?.message}
                  ></Input>
                  <Input
                    name={"personalDataToSend.dpcadera"}
                    type={"text"}
                    label={"*Medida cadera"}
                    placeholder={"En CM"}
                    register={register("personalDataToSend.dpcadera", {
                      required: "Este campo es obligatorio"
                    })}
                    error={errors.personalDataToSend?.dpcadera?.message}
                  ></Input>
                </div>
                <StepController
                  showPrevious={false}
                  onPreviousClick={handleGoBack}
                  onNextClick={handleFinishFirstStep}
                />
              </>
            )}
            {next === 2 && (
              <div className="weekly-activity-container">
                <p className="diet-train-p">
                  En este cuadrante
                  <span className="diet-train-p-bold">
                    <u>
                      indicanos tu rutina actual de entrenamiento y que vayas a mantener durante el
                      plan
                    </u>
                  </span>
                  . Cuentanos qué día a que hora (
                  <span className="diet-train-p-bold">formato 24h</span>) y qué tipo de actividad
                  realizas.
                  <span className="diet-train-p-bold">
                    Si no realizas ninguna actualmente déjalo en blanco.
                  </span>
                </p>
                <p style={{ fontWeight: "200", fontFamily: "Montserrat" }}>Cuéntanos tu rutina:</p>
                <div>
                  <div className="day-activity">
                    {days.map(({ key, label }) => (
                      <WeeklyInput
                        key={key}
                        label={label}
                        activityRegister={register(
                          `sportActivityDataToSend.weekly.${key}.activity`
                        )}
                        hourRegister={register(`sportActivityDataToSend.weekly.${key}.hour`)}
                      />
                    ))}
                  </div>
                </div>
                <p>
                  <span className="diet-train-p-bold">
                    Por último, índicanos tu actividad física diaria, fruto de tu vida cotidiana.
                    Ejemplo: Todos los días voy caminando al trabajo (1km), mi trabajo implica carga
                    de paquetes, subo andando las escaleras de mi casa (5 pisos)...
                  </span>
                </p>
                <textarea
                  maxLength={150}
                  placeholder="Actividad física general"
                  {...register("sportActivityDataToSend.general")}
                />
                <StepController
                  onPreviousClick={handleGoBack}
                  onNextClick={handleFinishSecondStep}
                />
              </div>
            )}
            {next === 3 && (
              <div className="weekly-activity-container">
                <p className="diet-train-p">
                  En este cuadrante
                  <span className="diet-train-p-bold">
                    <u>indicanos tu frecuencia de consumo de alimentos.</u>
                  </span>
                </p>
                <div>
                  <div className="day-activity">
                    {foodCategories.map(({ key, label }) => (
                      <FoodFrequency
                        key={key}
                        label={label}
                        register={register(`feedingDataToSend.frequency.${key}`)}
                      />
                    ))}
                  </div>
                </div>
                <p className="diet-train-p">
                  A continuación, cuéntanos como suelen ser habitualmente tus comidas. Si hay alguna
                  comida que no realizas, puedes dejarla en blanco.
                </p>
                <div>
                  <div className="day-activity meal">
                    {meals.map(({ key, label }) => (
                      <MealDescription
                        key={key}
                        label={label}
                        register={register(`feedingDataToSend.meals.${key}`)}
                      />
                    ))}
                  </div>
                </div>
                <StepController
                  onPreviousClick={handleGoBack}
                  onNextClick={handleFinishThirdStep}
                />
              </div>
            )}
            {next === 4 && (
              <>
                <h3>
                  <img src={partyIcon} alt="party icon" />
                  ¡YA CASI TERMINAMOS!
                  <img src={partyIcon} alt="party icon" />
                </h3>
                <p className="photos-description">
                  Para darte un mejor servicio necesitamos{" "}
                  <span className="photos-description-bold">
                    <u>tres fotos tuyas</u>
                  </span>
                  , una <span className="photos-description-bold">de frente</span>, otra{" "}
                  <span className="photos-description-bold">perfil</span> y otra de{" "}
                  <span className="photos-description-bold">espalda</span>. Las fotos deben ser en
                  traje de baño o ropa ajustada, en lugar con mucha luz y{" "}
                  <span className="photos-description-bold">sobre un fondo liso y blanco</span>.
                </p>
                <a
                  href="https://www.vikika.es/blog/las-claves-para-hacerte-bien-las-fotos-del-antes-y-despues/"
                  rel="noreferrer"
                  target="_blank"
                >
                  + Información para realizar tus fotos adecuadamente aquí.
                </a>

                <h4>Ejemplo de fotos correctas e incorrectas</h4>
                <img
                  src={screenShotExample}
                  alt="fotos correctas e incorrectas"
                  className="photo-change"
                />

                <h3 id="your-photos-title">¡TUS FOTOS!</h3>
                <div className="page3-form-container">
                  <div className="image-file-container">
                    {(["front", "side", "back"] as const).map(position => {
                      const file = watch(`photos.${position}`)
                      const imageUrl = file ? URL.createObjectURL(file) : undefined

                      return (
                        <div key={position} className={`img-section-${position} img-file-section`}>
                          <label
                            htmlFor={`img-person-${position}`}
                            className="custom-file-upload"
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center"
                            }}
                          >
                            <input
                              type="file"
                              id={`img-person-${position}`}
                              accept="image/gif, image/jpeg, image/png"
                              onChange={e => {
                                const file = e.target.files?.[0] || null
                                setValue(`photos.${position}`, file)
                              }}
                            />
                            {imageUrl ? null : (
                              <img
                                src={def_img}
                                alt="default img"
                                style={{ width: "80%", marginLeft: "10px", marginTop: "20px" }}
                              />
                            )}
                          </label>
                          <p>*{position.toUpperCase()}</p>
                        </div>
                      )
                    })}
                  </div>

                  <span className="instructions-images">
                    <img src={infoIcon} alt="icono de información" />
                    Cada foto debe estar en formato GIF, JPG, JPEG o PNG.
                  </span>
                </div>

                <div className="accept-box">
                  <input type="checkbox" id="comunicaciones" {...register("cnewsletter")} />
                  <label htmlFor="comunicaciones">
                    {" "}
                    Acepto recibir comunicaciones informativas
                  </label>
                </div>

                <div className="accept-box">
                  <input
                    type="checkbox"
                    id="privacidad"
                    {...register("cprivacidad", { required: true })}
                  />
                  <label htmlFor="privacidad">
                    *He leído y acepto la{" "}
                    <a href="/condiciones-generales" rel="noreferrer" target="_blank">
                      política de uso y conformidad legal
                    </a>
                  </label>
                </div>

                <p id="support-form-contact">
                  <img src={infoIcon} alt="icono de información" /> ¿Tienes algún problema con el
                  formulario? Contacta con soporte técnico.
                </p>

                <p id="support-form-contact-text">
                  Le informamos que los datos personales facilitados serán responsabilidad de COSTA
                  LIFE FIT S.L. con la finalidad de gestión de las compraventas. Más información en{" "}
                  <a href="https://vikika.es/privacidad" rel="noreferrer" target="_blank">
                    www.vikika.es
                  </a>
                </p>

                {errors.photos && (
                  <p className="instructions-send-form">Debe subir todas las fotos.</p>
                )}
                {errors.cprivacidad && (
                  <p className="instructions-send-form">Debe aceptar la política de privacidad.</p>
                )}

                <StepController showNext={false} onPreviousClick={handleGoBack} />

                {isSubmitting ? (
                  <div className="form-data-processing">
                    <CircularProgress color="inherit" size={20} thickness={3} />
                    <p className="instructions-send-form">Procesando, por favor espere.</p>
                  </div>
                ) : (
                  <button type="submit">Enviar</button>
                )}
              </>
            )}
          </form>
        </div>
      )}
      {hasBeenSent && (
        <div className="begin-form final-form">
          <h2>CUESTIONARIO INICIAL GEOPOL</h2>
          <div className="final-container">
            <span className="final-icon">✔</span>
            <h4 className="final-header">¡DATOS ENVIADOS!</h4>
            <p className="final-text">
              Te confirmamos que hemos recibido tu cuestionario correctamente.
            </p>
            <button className="final-button" onClick={() => navigate("/private")}>
              IR A MI ÁREA PRIVADA
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export type { GeopolQuestionary }
export default GeoPOLQuestionary

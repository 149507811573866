import "./HomePrivate.css"
import Interesting from "../../productDetails/interesting/Interesting"
import { useState, useEffect } from "react"
import { ProductType } from "../../../../models/ProductType"
import { PsiPlanData } from "../../../../models/PsiPlanData"
import { PsiSuscriptionData } from "../../../../models/PsiSuscriptionData"
import { VitalWellnessPsiData } from "../../../../models/VitalWellnessPsiData"
import { PlansActiveService } from "../../../../services/PlansActiveService"
import { useNavigate } from "react-router-dom"
import NotificationPrivate from "./notification/NotificationPrivate"
import { NotificationModel, NotificationsService } from "../../../../services/notificationsService"
import YourProducts from "./yourproducts/YourProducts"
import BuySubscriptions from "../buySubscriptions/BuySubscriptions"
import ReactDOMServer from "react-dom/server"
import useProducts from "../../../../hooks/useProducts"
import { PlansBoughtService } from "../../../../services/PlansBoughtService"
import { getIsGeopolPlan, getLastDietPlan } from "../../../../utils/dietPlanStorage"

interface HomePrivateProps {
  handleOptionClick: (option: string) => void
  chargeSidebar: boolean
  setChargeSidebar: (arg: boolean) => void
}

const HomePrivate = (homePrivateProps: HomePrivateProps) => {
  const { products, loading: isLoadingProducts } = useProducts()

  const [category, setCategory] = useState<string>("dieta")
  const [showedProducts, setShowedProducts] = useState<ProductType[]>([])
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const [ponUserInfoDietPlan, setPonUserInfoDietPlan] = useState<string | null>(null)
  const [ponUserInfoPsiPlan, setPonUserInfoPsiPlan] = useState<PsiPlanData | null>(null)
  const [ponUserInfoPsiCouplePlan, setPonUserInfoPsiCouplePlan] = useState<PsiPlanData | null>(null)
  const [ponUserInfoPsiSuscription, setPonUserInfoPsiSuscription] =
    useState<PsiSuscriptionData | null>(null)
  const [ponUserInfoDieMesPsi, setPonUserInfoDieMesPsi] = useState<VitalWellnessPsiData | null>(
    null
  )
  const navigate = useNavigate()

  async function recoverClientPlans() {
    // const responsePlansBought = { data: { plan_diet: null } }
    const responsePlansBought = await PlansBoughtService()
    const responsePlansActive = await PlansActiveService()

    const infoDiet = [
      ...(responsePlansBought.data.plan_diet || []),
      ...(responsePlansActive.data.plan_diet || [])
    ]

    localStorage.setItem("pon_user_info_diet", JSON.stringify(!infoDiet.length ? null : infoDiet))
    localStorage.setItem("pon_user_info_psi", JSON.stringify(responsePlansActive.data.plan_psi))
    localStorage.setItem(
      "pon_user_info_psi_couple",
      JSON.stringify(responsePlansActive.data.plan_psi_couple)
    )
    localStorage.setItem(
      "pon_user_info_psi_die_mes_psi",
      JSON.stringify(responsePlansActive.data.plan_die_mes_psi_psichology)
    )
    localStorage.setItem(
      "pon_user_info_psi_suscription",
      JSON.stringify(responsePlansActive.data.plan_psi_subscription)
    )
    if (responsePlansActive.data.plan_diet !== null) {
      setPonUserInfoDietPlan(responsePlansActive.data.plan_diet[0].typePlan)
    }
    if (responsePlansActive.data.plan_psi !== null) {
      setPonUserInfoPsiPlan({
        id: responsePlansActive.data.plan_psi[0].id,
        nsesiones: parseInt(responsePlansActive.data.plan_psi[0].nsesiones),
        nusadas: parseInt(responsePlansActive.data.plan_psi[0].nusadas),
        nacumuladas: parseInt(responsePlansActive.data.plan_psi[0].nacumuladas),
        ndisponibles: parseInt(responsePlansActive.data.plan_psi[0].ndisponibles)
      })
    }
    if (responsePlansActive.data.plan_psi_couple !== null) {
      setPonUserInfoPsiCouplePlan({
        id: responsePlansActive.data.plan_psi_couple[0].id,
        nsesiones: parseInt(responsePlansActive.data.plan_psi_couple[0].nsesiones),
        nusadas: parseInt(responsePlansActive.data.plan_psi_couple[0].nusadas),
        nacumuladas: parseInt(responsePlansActive.data.plan_psi_couple[0].nacumuladas),
        ndisponibles: parseInt(responsePlansActive.data.plan_psi_couple[0].ndisponibles)
      })
    }
    if (responsePlansActive.data.plan_die_mes_psi_psichology !== null) {
      setPonUserInfoDieMesPsi({
        nsesiones: parseInt(responsePlansActive.data.plan_die_mes_psi_psichology[0].nsesiones),
        nusadas: parseInt(responsePlansActive.data.plan_die_mes_psi_psichology[0].nusadas),
        nacumuladas: parseInt(responsePlansActive.data.plan_die_mes_psi_psichology[0].nacumuladas),
        ndisponibles: parseInt(
          responsePlansActive.data.plan_die_mes_psi_psichology[0].ndisponibles
        ),
        typePlan: responsePlansActive.data.plan_die_mes_psi_psichology.typePlan
      })
    }
    if (responsePlansActive.data.plan_psi_subscription !== null) {
      setPonUserInfoPsiSuscription({
        id: responsePlansActive.data.plan_psi_subscription[0].id,
        nsesiones: parseInt(responsePlansActive.data.plan_psi_subscription[0].nsesiones),
        nusadas: parseInt(responsePlansActive.data.plan_psi_subscription[0].nusadas),
        nacumuladas: parseInt(responsePlansActive.data.plan_psi_subscription[0].nacumuladas),
        ndisponibles: parseInt(responsePlansActive.data.plan_psi_subscription[0].ndisponibles),
        fProxRenovación: responsePlansActive.data.plan_psi_subscription[0].fProxRenovacion
      })
    }
    homePrivateProps.setChargeSidebar(true)
  }

  async function fetchProducts() {
    const pon_user_info_diet = JSON.parse(localStorage.getItem("pon_user_info_diet") || "{}")
    const pon_user_info_psi = JSON.parse(localStorage.getItem("pon_user_info_psi") || "{}")
    const pon_user_info_psi_suscription = JSON.parse(
      localStorage.getItem("pon_user_info_psi_suscription") || "{}"
    )
    const filteredProducts = products.filter((product: { id: number }) => {
      if (!pon_user_info_psi && !pon_user_info_psi_suscription && !pon_user_info_diet) {
        return product.id !== 3
      } else if (pon_user_info_diet && !pon_user_info_psi && !pon_user_info_psi_suscription) {
        return product.id !== 1
      } else if ((pon_user_info_psi || pon_user_info_psi_suscription) && !pon_user_info_diet) {
        return product.id !== 2
      } else if ((pon_user_info_psi || pon_user_info_psi_suscription) && pon_user_info_diet) {
        return product.id !== 2
      }
    })
    setShowedProducts(filteredProducts)
  }
  async function fetchNotifications() {
    // setNotifications([
    //   [
    //     {
    //       title: "Tienes un nuevo mensaje",
    //       subtitle:
    //         'Tu especialista te ha escrito a trav\u00e9s del chat. Puedes revisar tu chat en el apartado \n                    <a href="#" class="home-private-links">contacta con tu especialista</a>',
    //       cta: "general.message"
    //     },
    //     {
    //       title: "\u00a1Agenda tu videollamada!",
    //       subtitle:
    //         'Ya tienes listo tu plan. Accede a la secci\u00f3n de  <a href="#" class="home-private-links prod-diet">"Tu gu\u00eda nutricional"</a> y rev\u00edsalo con detenimiento para comentar lo que necesites en la videollamada con tu especialista. Accede a la secci\u00f3n del <a href="#" class="home-private-links diet-videocall-dates">calendario</a> para agendar tu videollamada. Recuerda que tienes 5 d\u00edas para agendarla. ',
    //       cta: "book.videocall"
    //     }
    //   ]
    // ])
    const notificationsdata: NotificationModel[] = await NotificationsService()
    setNotifications(notificationsdata)
  }

  async function recoverData() {
    try {
      await recoverClientPlans()
      await fetchProducts()
      fetchNotifications()
    } catch (err) {
      await fetchProducts()
      console.error("Error recovering customer data in private home:", err)
    }
  }

  useEffect(() => {
    recoverData()
    return () => {
      homePrivateProps.setChargeSidebar(false)
    }
  }, [products, isLoadingProducts])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (target.classList.contains("home-private-links")) {
        switch (target.innerHTML) {
          case "calendario":
            const lastDietPlan = getLastDietPlan()
            const isGeopolPlan = !lastDietPlan ? false : getIsGeopolPlan(lastDietPlan)
            isGeopolPlan
              ? homePrivateProps.handleOptionClick("Calendar")
              : homePrivateProps.handleOptionClick("Schedule Session")
            break
          case "calendario en pareja":
            homePrivateProps.handleOptionClick("Schedule Session Couple")
            break
          case "calendario vital wellness":
            homePrivateProps.handleOptionClick("Schedule Session DietMesPsi")
            break
          case "contacta con tu psicólog@":
            homePrivateProps.handleOptionClick("Contact Specialist Psychology")
            break
          case "contacta con tu psicólog@ de pareja":
            homePrivateProps.handleOptionClick("Contact Specialist Psychology Couple")
            break
          case "contacta con tu especialista":
            homePrivateProps.handleOptionClick("Contact Specialist")
            break
          case "calendario de dieta":
            homePrivateProps.handleOptionClick("Calendar")
            break
          case "tus citas de psicología":
            homePrivateProps.handleOptionClick("Schedule Session")
            break
          case "tus citas de psicología en pareja":
            homePrivateProps.handleOptionClick("Schedule Session Couple")
            break
          case "cuestionario Inicial":
            const newWindow = window.open("", "_blank", "width=600,height=400")
            if (newWindow) {
              const componentHtml = ReactDOMServer.renderToString(<BuySubscriptions />)
              newWindow.document.write(componentHtml)
              newWindow.document.close()
            }
            break
          case 'productos "plan de psicología"':
            navigate("/productos/psicologia")
            break
        }
        if (target.classList.contains("schedule-videocall")) {
          homePrivateProps.handleOptionClick("Schedule Session Videocall")
        }
        if (target.classList.contains("schedule-videocall-subscription")) {
          homePrivateProps.handleOptionClick("Schedule Session Suscription")
        }
        if (target.classList.contains("schedule-videocall-subscription-dates")) {
          homePrivateProps.handleOptionClick("Schedule Session Suscription Dates")
        }
        if (target.classList.contains("schedule-videocall-pair")) {
          homePrivateProps.handleOptionClick("Schedule Session Couple VideoCall")
        }
        if (target.classList.contains("diet-videocall")) {
          homePrivateProps.handleOptionClick("Calendar Videocall")
        }
        if (target.classList.contains("prod-psych")) {
          homePrivateProps.handleOptionClick("Schedule Session")
        }
        if (target.classList.contains("prod-psych-pair")) {
          homePrivateProps.handleOptionClick("Schedule Session Couple")
        }
        if (target.classList.contains("prod-diet")) {
          homePrivateProps.handleOptionClick("Your Diet")
        }
      }
    }

    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const handleProductClick = (product: ProductType) => {
    if (product.category == "psychology") {
      setCategory("psicologia")
    }
    if (product.category == "workshop") {
      setCategory("talleres")
    }
    navigate(`/productos/${category}`)
  }

  return (
    <main>
      <div className="home-private-container">
        {Array.isArray(notifications) &&
          notifications.map(
            cardArray =>
              Array.isArray(cardArray) &&
              cardArray.map((card, index) => {
                const cta = card.cta.toLowerCase()
                const hasSubstring = cta.includes("psi")
                return (
                  <NotificationPrivate
                    key={index}
                    title={card.title}
                    subtitle={card.subtitle}
                    cta={card.cta}
                    id={hasSubstring ? "rouge" : ""}
                    type_plan={card.type_plan}
                    pay_plan={card.pay_plan}
                  />
                )
              })
          )}

        <div className="your-products-general">
          <YourProducts
            handleOptionClick={homePrivateProps.handleOptionClick}
            ponUserInfoDietPlan={ponUserInfoDietPlan}
            ponUserInfoPsiPlan={ponUserInfoPsiPlan}
            ponUserInfoPsiCouplePlan={ponUserInfoPsiCouplePlan}
            ponUserInforPsiSuscriptionPlan={ponUserInfoPsiSuscription}
            ponUserInfoDieMesPsi={ponUserInfoDieMesPsi}
          />

          <div className="home-private-others-products">
            <div className="home-private-others-products-title">
              <h1>PRODUCTOS RECOMENDADOS</h1>
            </div>
            <div className="product-cards-containers">
              {showedProducts.length > 0 &&
                showedProducts.map(product => (
                  <Interesting
                    key={product.id}
                    {...product}
                    onClick={() => handleProductClick(product)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default HomePrivate
